import React, { useEffect, useState } from "react";
import "../../../../App.css";
import { Link, useNavigate } from "react-router-dom";
import {
  MdAdd,
  MdGroup,
  MdDeleteForever,
  MdMessage,
  MdLogin,
  MdDetails,
  MdInfo,
  MdHandshake,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersGroupAndTeams } from "../../../global-context/Slice";
import axios from "axios";

export default function Groups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentGroups = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer.groups
  );
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const groupsAndTeams = useSelector(
    (state) => state.fetchUsersGroupAndTeamsReducer
  );
  const groups = groupsAndTeams.groups;

  const suggestedGroupsOriginal = useSelector(
    (state) => state.fetchUsersMatchedReducer.groups
  );

  const groupsId = groups.map((group) => group.id);
  const suggestedGroups = suggestedGroupsOriginal.filter(
    (group) => !groupsId.includes(group.id)
  );


  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false); // Nasconde il loader dopo 6 secondi
    }, 4000);

    return () => clearTimeout(timeout); // Pulisce il timeout quando il componente si smonta
  }, []);


  function getGroupDaysLeft(stringDate) {
    const date = new Date(stringDate);
    const today = new Date();

    const diffTime = Math.abs(today - date);

    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const daysLeft = 30 - diffDays;

    return daysLeft;
  }

  const goToChatPage = (item) => {
    navigate(`/chat/${item.id}`, {
      state: { ...item, chatType: "group", room_id: item.id },
    });
  };

  const goToGroupDetailPage = (item) => {
    navigate(`/match/group-detail/${item.id}`, {
      state: { ...item },
    });
  };

  const deleteGroupPartecipation = async (groupId, userId) => {
    const response = await axios.post(
      `${process.env.REACT_APP_NODEJS_URL}/deleteGroupPartecipation`,
      { userId: userId, groupId: groupId }
    );
    if (response.status === 201) {
      dispatch(
        fetchUsersGroupAndTeams({
          groups: currentGroups.filter((item) => item.id != groupId),
        })
      );
    }
  };

  const partecipationDeletionCheck = (groupId, userId) => {
    const confirmation = window.confirm(
      "Are You sure that you want to exit the group? "
    );
    if (confirmation) {
      deleteGroupPartecipation(groupId, userId);
    }
  };

  const GroupIterator = ({ groupsVariable }) => {
    return groupsVariable.map((item, index) => {
      return (
        <div className="section matchingSection" key={index}>
          {item.type === "IS_CREATOR" && (
            <div
              className="groupsViewItemsLabel"
              style={{ backgroundColor: "#b10491" }}
            >
              <div>
                <p>Owner</p>
              </div>
            </div>
          )}
          {item.type === "IS_MEMBER" && (
            <div
              className="groupsViewItemsLabel"
              style={{ backgroundColor: "#f8a64b" }}
            >
              <div>
                <p>Member</p>
              </div>
            </div>
          )}
          <div className="groupsViewItemsTitle">
            <h3>{item.businessSector}</h3>
          </div>
          <hr style={{ width: "100%", margin: "5px 2px 0px 2px" }} />
          <div className="groupsViewItemsSection" style={{ marginTop: "5px" }}>
            <h4>{item.title}</h4>
            <p style={{ marginTop: "5px" }}>{item.description}</p>

            <div className="matchingPeopleButtons" style={{ width: "95%" }}>
              {(item.type === "IS_MEMBER") | (item.type === "IS_CREATOR") ? (
                <div
                  className=""
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "30%",
                  }}
                >
                  <div
                    className="matchingActionPeopleButton"
                    onClick={() => goToChatPage(item)}
                  >
                    <MdMessage size={25} />
                  </div>
                  <div
                    className="matchingActionPeopleButton"
                    onClick={() =>
                      partecipationDeletionCheck(item.id, userData.id)
                    }
                  >
                    <MdDeleteForever size={25} />{" "}
                  </div>
                  {/* <div
                    className="matchingActionPeopleButton"
                    onClick={() => goToGroupDetailPage(item)}
                  >
                    <MdHandshake size={25} />
                  </div> */}
                </div>
              ) : (
                <div
                  className="matchingActionPeopleButton"
                  style={{ margin: "0 auto 0 5px" }}
                  onClick={() => goToGroupDetailPage(item)}
                >
                  <MdInfo size={25} />
                </div>
              )}
              <div className="groupsMembersNumber">
                <MdGroup size={20} />
                <p
                  style={{
                    alignSelf: "center",
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginLeft: "3px",
                  }}
                >
                  {item.memberIds
                    ? item.memberIds.length
                    : item.numberOfmembers}
                  /{item.maxParticipants}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const AddButton = () => {
    return (
      <Link to="/match/createGroup" className="groupsButtonCreateNew">
        <MdAdd />
      </Link>
    );
  };

  return (
    <div className="groupsMain">
      {(groups.length > 0) | (suggestedGroups.length > 0) ? (
        <div style={{ width: "95%" }}>
          <div>
            <h3 className="groupsPageTitles" style={{ margin: "4px 0 20px 0" }}>
              {" "}
              Your Groups
            </h3>
            {groups.length > 0 && (
              <div>
                <GroupIterator groupsVariable={groups} />
              </div>
            )}
            {groups.length < 3 ? (
              <div className="groupsCreateNew">
                <p> Create one !</p>
                <AddButton />
              </div>
            ) : (
              <div className="groupsCreateNew">
                <p>You reached the max number of groups</p>
              </div>
            )}
          </div>

          {groups.length > 0 && <hr style={{ margin: "25px 0 25px 0" }} />}
          <div>
            <h3 className="groupsPageTitles" >
              Suggested Groups
            </h3>
            <div style={{ marginBottom: "50px" }}>
              <GroupIterator groupsVariable={suggestedGroups} />
            </div>
          </div>
        </div>
      ) : (
        <div className="groupsCreateNew" style={{ marginTop: "50px" }}>
          <p>It seems that you have not any groups yet !</p>
          <p> Create one !</p>
          <AddButton />
        </div>
      )}
    </div>
  );
}
