import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AWS from "aws-sdk";

import { useSelector, useDispatch } from "react-redux";
import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  MdInsertComment,
  MdThumbUp,
  MdArrowDropDown,
  MdArrowBack,
  MdSend,
  MdDelete,
  MdEdit,
  MdModeEditOutline,
  MdOutlineEdit,
  MdOutlineDelete,
} from "react-icons/md";
import { Button, Input, Select } from "antd";
import RewardDeductionFunction from "../../../hooks/NcRewardDeductionFunction";

export default function SinglePostPage() {


  const location = useLocation();
  const fetchRef = useRef(false);
  const postFetchRef = useRef(false);
  const commentsViewEndRef = useRef(null);
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const userDataId = userData.id;
  const [post, setPost] = useState();
  const [comments, updateComments] = useState([]);
  const [commentsPage, setCommentsPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [areCommentsLoading, setAreCommentsLoading] = useState();
  const [isPostLikedNow, likePost] = useState();
  const [isPostLoading, setIsPostLoading] = useState(true);
  const [writedComment, setWritedComment] = useState("");
  const [replyToUserId, setReplyToUserId] = useState("");
  const [replyToUser, setReplyToUser] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState("");
  const { postId } = useParams();

  const getComments = async () => {
    if (!hasMore) return;
    if (!fetchRef.current) {
      fetchRef.current = true;
      try {
        const comments = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getComments`,
          {
            id: postId,
            page: commentsPage,
            limit: 3,
          }
        );

        const commentsArray = Object.values(comments.data);
        const sortedComments = commentsArray.sort(
          (a, b) => a.timestamp - b.timestamp
        );
        updateComments((prevComments) => {
          const mergedComments = [...sortedComments, ...prevComments];

          return mergedComments.sort((a, b) => a.timestamp - b.timestamp);
        });
        setHasMore(commentsArray.length > 0);
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!postFetchRef.current) {
        const postInformation = await axios.post(
          `${process.env.REACT_APP_NODEJS_URL}/getSinglePost`,
          {
            id: postId,
            userId: userDataId,
          }
        );
        postFetchRef.current = true;
        setPost(postInformation.data);
        setIsPostLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getComments();
  }, [commentsPage, getComments]);

  const hanldeCommentSubmit = async () => {
    if (writedComment != "") {
      const currentTimestamp = Date.now();
      const commentObject = {
        postId: postId,
        userId: userDataId,
        creatorId: userDataId,
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileImgLink: userData.imgProfileLocation,
        id: `${postId}${userDataId}${currentTimestamp}`,
        timestamp: currentTimestamp,
        text: writedComment,
        responseToUserId: replyToUserId,
        responseToUser: replyToUser,
        responseToCommentId: replyToCommentId,
      };

      await axios.post(
        `${process.env.REACT_APP_NODEJS_URL}/commentPost`,
        commentObject
      );

      updateComments((prevComments) => [...prevComments, commentObject]);
      setWritedComment("");
      setReplyToUserId("");
      setReplyToCommentId("");
      setReplyToUser("");
    }
  };

  const deleteComment = async (idToRemove) => {
    await axios.post(`${process.env.REACT_APP_NODEJS_URL}/deleteComment`, {
      commentId: idToRemove,
      postId: postId,
      userId: userDataId,
    });

    updateComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== idToRemove)
    );
    setWritedComment("");
  };

  const IerateComments = () => {
    if (comments.length > 0) {
      return comments.map((item, key) => (
        <div style={{ padding: "5px", margin: "10px 5px" }}>
          <div
            className="metaData"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <img
              src={item.profileImgLink}
              style={{
                display: "block",
                height: "35px",
                borderRadius: "50px",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "85vw",
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    marginLeft: "8px",
                  }}
                >
                  {item.firstName} {item.lastName}
                </div>
                <div
                  style={{
                    color: "#aaaaaa",
                    fontSize: "12px",
                    marginLeft: "8px",
                    justifyContent: "baseline",
                  }}
                >
                  {getTimeDiff(item.timestamp)}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    marginRight: "5px",
                  }}
                >
                  {item.creatorId === userDataId && (
                    <div>
                      <MdOutlineDelete
                        onClick={() => deleteComment(item.id)}
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                  )}
                  <MdArrowDropDown />
                </div>
              </div>
              <div
                style={{
                  marginLeft: "8px",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  width: "80vw",
                }}
              >
                {item.responseToUser && (
                  <div
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      display: "flex",
                      color: "#888888",
                    }}
                  >
                    <p style={{ backgroundColor: "#eeeeee", padding: "4px", borderRadius:'10px' }}>
                      to: {item.responseToUser}
                    </p>
                  </div>
                )}
                {item.text}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "#888888",
                  margin: "5px 8px",
                }}
                onClick={() => {
                  setReplyToUserId(item.creatorId);
                  setReplyToCommentId(item.id);
                  setReplyToUser(`${item.firstName} ${item.lastName}`);
                }}
              >
                <p>Reply</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  const getTimeDiff = (timestamp) => {
    const now = Date.now();
    const diffInMilliseconds = Math.abs(now - timestamp); // Differenza in millisecondi
    const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60)); // Conversione in ore
    const diffInMin = Math.floor(diffInMilliseconds / (1000 * 60));
    if (diffInHours === 0 && diffInMin > 0) {
      return `${diffInMin} min`;
    } else if (diffInHours === 0 && diffInMin === 0) {
      return "now";
    } else if (diffInHours < 24) {
      return `${diffInHours} h`;
    } else {
      return `${Math.floor(diffInHours / 24)} d`;
    }
  };

  let likesNumber = 0;
  if (post) {
    likesNumber = post?.isLikedByUser
      ? isPostLikedNow
        ? parseInt(post.likesNumber) + 1
        : parseInt(post.likesNumber)
      : parseInt(post.likesNumber) > 0
      ? parseInt(post.likesNumber) - 1
      : 0;
  }

  if (isPostLoading) {
    return <CustomClipperLoader />;
  } else {
    return (
      <div
        style={{
          height: "100vh",
          // overflowY: "scroll",
          paddingBottom: "80px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <div style={{ margin: "10px 10px 0px" }}>
          <div
            className="postheader"
            style={{
              display: "flex",
              flexDirection: "row",
              alignposts: "center",
            }}
          >
            <div className="postCreatorImg">
              <img
                src={post.profileImgLink}
                style={{
                  display: "block",
                  height: "40px",
                  borderRadius: "40px",
                  margin: "5px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  className="postCreatorName"
                  style={{ fontWeight: "bold", fontSize: "15px" }}
                >
                  {`${post.firstName} ${post.lastName}`}
                </div>
                <div
                  style={{
                    margin: "0px 0px 0px 8px",
                    fontSize: "13px",
                    color: "#999999",
                  }}
                >
                  {getTimeDiff(post.timestamp)}
                </div>
              </div>
              {post.postTags && (
                <div
                  style={{
                    width: "fit-content",
                    display: "inline-block",
                    fontSize: "12px",
                    padding: "3px",
                    borderRadius: "10px",
                    color: "white",
                    backgroundColor: "#999999",
                  }}
                >
                  {" "}
                  <div> {post.postTags}</div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "auto",
                marginRight: "5px",
              }}
            >
              <MdArrowDropDown />
            </div>
          </div>
          <div
            className="postMain"
            style={{ minHeight: "15vmax", padding: "10px" }}
          >
            <div
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 5, // Limita il numero di righe
                overflow: "hidden",
                maxHeight: "30vmax",
              }}
            >
              {post.text}
            </div>
            <div>
              {post.mediaLink && (
                <img
                  style={{
                    display: "block",
                    width: "100%",
                    marginTop: "15px",
                    borderRadius: "10px",
                  }}
                  src={post.mediaLink}
                />
              )}
            </div>
          </div>

          <div
            className="postFooter"
            style={{
              display: "flex",
              flexDirection: "row",
              alignposts: "center",
              margin: "8px 10px",
            }}
          ></div>
        </div>

        <hr
          style={{
            height: "1px",
            width: "90%",
            backgroundColor: "#cccccc",
            margin: "auto",
          }}
        />
        <div className="postCommentsSection">
          {comments.length > 0 ? (
            <div>
              {hasMore && (
                <div
                  onClick={() => {
                    setCommentsPage(commentsPage + 1);
                    fetchRef.current = false;
                  }}
                  style={{ color: "blue", fontSize: "13px", margin: "5px" }}
                >
                  Load more...
                </div>
              )}

              <div className="allCommentsSection">
                <IerateComments />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#777777",
                margin: "30px",
              }}
            >
              <p>There are no comments yet</p>
            </div>
          )}
          {replyToCommentId != "" &&
            replyToUserId != "" &&
            replyToUser != "" && (
              <div
                style={{
                  backgroundColor: "#cccccc",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                <p>Repling to {replyToUser}</p>
              </div>
            )}
          <div style={{ height: "80px" }} ref={commentsViewEndRef} />

          <div
            className="createComment"
            style={{
              position: "fixed",
              bottom: "40px",
              left: "0",
              right: "0",
              backgroundColor: "white",
              zIndex: "999",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input.TextArea
              value={writedComment}
              onChange={(e) => setWritedComment(e.target.value)}
              placeholder="your comment..."
              style={{ width: "80vw", marginLeft: "10px", height: "30px" }}
            />

            <MdSend
              size={20}
              onClick={hanldeCommentSubmit}
              style={{ margin: "10px" }}
            />
          </div>
        </div>
      </div>
    );
  }
}
