import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../../../global-context/Slice";
import "../../../../App.css";
import { MdEdit, MdCheck } from "react-icons/md";
import axios from "axios";

import "cropperjs/dist/cropper.css";
import { Button } from "antd";

export default function BioArea({
  setModifyBio,
  setSelectedBio,
  selectedBio,
  modifyBio,
}) {
  const [changedBio, setChangedBio] = useState(selectedBio ? selectedBio : "");
  const userData = useSelector((state) => state.fecthUserDataReducer);
  const dispatch = useDispatch();

  const sendUpdateToNeo4j = (object) => {
    axios.post(`${process.env.REACT_APP_NODEJS_URL}/createUpdate`, object);
  };

  const updateSelectedItems = (sectionClass, selectedItems) => {
    let changes = { id: userData.id };
    changes[sectionClass] = selectedItems;
    dispatch(fetchUserData(changes));
    sendUpdateToNeo4j({ ...changes });
  };

  const handleChange = (e) => {
    // Here we are checking if the length is equal to 10
    if (e.target.value.length === 250) {
      window.alert("Username shouldn't exceed 10 characters");
    }
    setChangedBio(e.target.value);
  };

  const showHideOptionsAndSaveChanges = () => {
    if (modifyBio === true) {
      setSelectedBio(changedBio);
      updateSelectedItems("bio", changedBio);
      setModifyBio(!modifyBio);
    } else {
      setModifyBio(!modifyBio);
    }
  };

  return (
    <div className="section">
      <div className="sectionHeader">
        <h2>Bio</h2>
        <div
          onClick={showHideOptionsAndSaveChanges}
          style={{ cursor: "pointer" }}
        >
          {modifyBio ? undefined : <MdEdit />}
        </div>
      </div>
      <div className="bioContainer">
        {modifyBio ? (
          <div>
            <input
              name="bio"
              className="bioInput"
              type="text"
              maxLength={250}
              value={changedBio}
              onChange={handleChange}
              placeholder="Inserisci una descrizione di te stesso. Non aver paura di andare a fondo."
              size="large"
            />
            <div className="bioLengthContainer">{changedBio.length}/250</div>
          </div>
        ) : (
          <p>{selectedBio}</p>
        )}
      </div>
      {modifyBio && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "auto",
            width: "100px",
          }}
        >
          <Button
            type="primary"
            className="form_button"
            color={"#208AEC"}
            size="middle"
            onClick={showHideOptionsAndSaveChanges}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  );
}
