import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AWS from "aws-sdk";

import { useSelector, useDispatch } from "react-redux";
import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MdAdd,
  MdArrowBack,
  MdCancel,
  MdDelete,
  MdOutlineToggleOff,
  MdPhoto,
  MdTag,
  MdToggleOn,
} from "react-icons/md";
import { Button, Input, Select } from "antd";
import RewardDeductionFunction from "../../../hooks/NcRewardDeductionFunction";
import { saveCreatedPost} from "../../../global-context/Slice";

export default function CreatePost() {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isTargetOptionsOpened, setTargetOptionsOpened] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedBusinessInterest, setSelectedBusinessInterest] = useState([]);
  const [selectedBusinessExp, setSelectedBusinessExp] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [mediaLink, setMediaLink] = useState();
  const [isTagOptionsClicked, setTagOptionsClicked] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [textLines, setTextLines] = useState(3);
  const [progress, setProgress] = useState(0);
  const [tagOptionSelected, setTagOptionSelected] = useState("default");
  const [isPublishing, setIsPublishing] = useState(false);
  const [isProPost, setIsProPost] = useState(false);

  const customSearchNcCost = -150;
  const { Option } = Select;
  const userData = useSelector((state) => state.fecthUserDataReducer);

  const categories = useSelector((state) => state.fecthCategoriesReducer);
  const skills = categories.skills.map((object) => object.items).flat();
  const hobbies = categories.hobbies.map((object) => object.items).flat();
  const businesses = categories.businessTypes
    .map((object) => object.items)
    .flat();
  const tags = [
    { name: "Help", color: "red", pro: false },
    { name: "Share", color: "green", pro: false },
  ];

  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const S3_BUCKET = "doinitpostsmedia";
  const REGION = "eu-central-1";

  const sendPostToNeo4j = async (object) => {
    console.log("handle neo4j");
    axios.post(`${process.env.REACT_APP_NODEJS_URL}/createPost`, object);
  };

  const getPostType = () => {
    if (
      selectedSkills.length > 0 ||
      selectedBusinessInterest.length > 0 ||
      selectedBusinessExp.length > 0 ||
      selectedHobbies.length > 0
    ) {
      setIsProPost(true);
      return true;
    } else {
      return false;
    }
  };

  const handleAwsUpload = async () => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: `${userData.id}/${selectedImg.file.name}`, //
      Body: selectedImg.file,
    };

    s3.putObject(params, (err, data) => {
      if (err) {
        console.error(err);
      } else {
        const uri = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${params.Key}`;
        const objectToUpload = {
          userId: userData.id,
          timestamp: new Date().getTime(),
          id: `${userData.id}${new Date().getTime()}`,
          text: textValue,
          postTags: tagOptionSelected === "default" ? "" : tagOptionSelected,
          postType: isProPost ? "TARGETTED" : "SIMPLE",
          toInterests: selectedBusinessInterest,
          toHobbies: selectedHobbies,
          toSkills: selectedSkills,
          toExperiences: selectedBusinessExp,
          mediaLink: uri,
        };
        console.log(objectToUpload);

        sendPostToNeo4j(objectToUpload);
      }
    }).on("httpUploadProgress", (evt) => {
      setProgress(Math.round((evt.loaded / evt.total) * 100));
    });
  };

  const handleUpload = async () => {
    if (selectedImg) {
      await handleAwsUpload();
    } else {
      const objectToUpload = {
        userId: userData.id,
        timestamp: new Date().getTime(),
        id: `${userData.id}${new Date().getTime()}`,
        text: textValue,
        postTags: tagOptionSelected === "default" ? "" : tagOptionSelected,
        postType: isProPost ? "TARGETTED" : "SIMPLE",
        toInterests: selectedBusinessInterest,
        toHobbies: selectedHobbies,
        toSkills: selectedSkills,
        toExperiences: selectedBusinessExp,
        mediaLink: "",
      };
      console.log(objectToUpload);

      await sendPostToNeo4j(objectToUpload);
      dispatch(saveCreatedPost({post: objectToUpload}));
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setSelectedImg({
        uri: URL.createObjectURL(selectedFile),
        file: selectedFile,
      });
    }
  };

  const countLines = (text) => {
    return text.split("\n").length;
  };

  const handleTextChange = (event) => {
    const newValue = event.target.value;
    const countLine = countLines(newValue);
    if (countLine > 3) {
      setTextLines(countLine);
    } else if (countLine < 3) {
      setTextLines(3);
    }
    setTextValue(newValue);
  };

  const handlePublishPost = async () => {
    setIsPublishing(true);
    const proPost = getPostType();
    if (!proPost) {
      console.log("post non pro");
      await handleUpload();
    }
    setIsPublishing(false);

    navigate("/feed");
  };

  return (
    <div style={{ margin: "20px" }}>
      {isProPost && (
        <RewardDeductionFunction
          increment={customSearchNcCost}
          userData={userData}
          type="deduction"
          continueFunction={() => {
            handleUpload();
            setIsProPost(false);
          }}
        />
      )}
      <input
        type="file"
        accept="image/*,video/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleImageChange}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ marginRight: "20px", display: "flex", alignItems: "center" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBack size={30} />
        </div>
        <h2>Create Post</h2>
      </div>
      <div
        style={{
          border: "1px solid #cccccc",
          borderRadius: "20px",
          padding: "5px",
          margin: "20px 0px",
        }}
      >
        {selectedImg && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "5px",
              borderRadius: "20px",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            <div
              style={{ display: "flex", margin: "5px 5px 0px auto" }}
              onClick={() => setSelectedImg(null)}
            >
              <MdCancel size={20} />
            </div>
            <img src={selectedImg.uri} style={{ height: "200px" }} />
          </div>
        )}
        <Input.TextArea
          className="createPostText"
          placeholder="What would you like to talk about?"
          rows={textLines}
          onChange={handleTextChange}
        />

        {tagOptionSelected != "default" && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "10px",
              fontSize: "14px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignContent: "center",
                backgroundColor: tags.filter(
                  (item) => item.name == tagOptionSelected
                )[0]?.color,
                borderRadius: "10px",
                color: "white",
                fontWeight: "bold",
                padding: "4px",
              }}
            >
              {tagOptionSelected}
              <MdCancel
                size={20}
                onClick={() => setTagOptionSelected("default")}
              />
            </div>
          </div>
        )}

        {isTagOptionsClicked ? (
          <Select
            variant="borderless"
            open={true}
            value={tagOptionSelected}
            onChange={setTagOptionSelected}
            onSelect={() => {
              setTagOptionsClicked(!isTagOptionsClicked);
            }}
          >
            {tags.map((option) => (
              <Option key={option.name} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        ) : (
          <Button style={{ border: "none" }} onClick={setTagOptionsClicked}>
            <MdTag size={20} />
          </Button>
        )}

        <Button style={{ border: "none" }} onClick={handleImageClick}>
          <MdPhoto size={20} />
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
          width: "70%",
          marginTop: "10px",
        }}
        onClick={() => setTargetOptionsOpened(!isTargetOptionsOpened)}
      >
        <h3 style={{ margin: "-4px 20px 0px 0px" }}>Target Options</h3>

        {isTargetOptionsOpened ? (
          <MdToggleOn size={30} />
        ) : (
          <MdOutlineToggleOff size={30} />
        )}
      </div>
      <div>
        <p style={{ fontSize: "15px", color: "#aaaaaa", marginTop: "8px" }}>
          These are Pro options. If you use them without having a Pro account,
          you will be asked to use your NetCoin.
        </p>
      </div>
      {isTargetOptionsOpened && (
        <div>
          <div style={{ marginTop: "20px" }}>
            <div className="groupsCreateFormSection">
              <h4> Business Experience</h4>
              <Select
                mode="multiple"
                className="bioInput"
                style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                maxTagCount={2}
                variant="borderless"
                placeholder="Select business types"
                value={selectedBusinessExp}
                onChange={setSelectedBusinessExp}
                required
              >
                {businesses.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="groupsCreateFormSection">
              <h4> Business Interests</h4>
              <Select
                mode="multiple"
                className="bioInput"
                style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                maxTagCount={2}
                variant="borderless"
                placeholder="Select business types"
                value={selectedBusinessInterest}
                onChange={setSelectedBusinessInterest}
                required
              >
                {businesses.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="groupsCreateFormSection">
              <h4> Skill </h4>
              <Select
                mode="multiple"
                className="bioInput"
                style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                maxTagCount={2}
                variant="borderless"
                placeholder="Select preferred skills categories"
                value={selectedSkills}
                onChange={setSelectedSkills}
                required
              >
                {skills.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="groupsCreateFormSection">
              <h4> Hobbies</h4>
              <Select
                mode="multiple"
                className="bioInput"
                style={{ marginTop: "2%", padding: "3px", flexWrap: "wrap" }}
                maxTagCount={2}
                variant="borderless"
                placeholder="Select preferred hobbies categories"
                value={selectedHobbies}
                onChange={setSelectedHobbies}
                required
              >
                {hobbies.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex" }}>
        {isPublishing ? (
          <Button
            type="primary"
            loading
            style={{
              backgroundColor: "#f8a64b",
              fontWeight: "bold",
              alignSelf: "center",
              margin: "20px auto",
            }}
          >
            Loading...
          </Button>
        ) : (
          <Button
            type="primary"
            style={{
              backgroundColor: "#f8a64b",
              fontWeight: "bold",
              alignSelf: "center",
              margin: "20px auto",
            }}
            onClick={handlePublishPost}
          >
            Post
          </Button>
        )}
      </div>
      <div style={{ height: "40px" }} />
    </div>
  );
}
