import React, { useEffect, useState } from "react";
import "../../../../App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CustomClipperLoader from "../../../hooks/CustomClipperLoader";
import MatchingPeopleIterator from "./peopleResumeIterator";

export default function People() {
  const usersMatched = useSelector((state) => state.fetchUsersMatchedReducer);
  const userDataId = useSelector((state) => state.fecthUserDataReducer.id);
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(false); // Nasconde il loader dopo 6 secondi
    }, 4000);

    return () => clearTimeout(timeout); // Pulisce il timeout quando il componente si smonta
  }, []);


  return (
    <div className="mainPeople ">
      <div>
        {usersMatched?.users?.length === 0 && showLoader && <CustomClipperLoader />}
        {usersMatched?.users?.length === 0 && !showLoader && <div><p>No People to match</p></div>}
        {usersMatched.users.length > 0 && (
          <MatchingPeopleIterator
            matchingPeople={usersMatched.users}
            userDataId={userDataId}
            navigate={navigate}
            buttonsEnabled={true}
          />
        )}
      </div>
    </div>
  );
}
