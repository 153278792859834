import { Button } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage({Parse}) {
    const navigate = useNavigate()
    const logOutAndGoBack = async () => {
    const currentUser = Parse.User.current();
    if (currentUser) {
      Parse.User.logOut();
    };
    navigate('/HomePage')
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "auto",
      }}
    >
      <div className="header">
        <img
          className="header_logo"
          alt="Doinit Logo"
          src={require("../../assets/logo.png")}
        />
      </div>
      <div>
        <img
          className="error_emoji"
          alt="error_emoji"
          src={require("../../assets/error_emoji.png")}
        />
      </div>
      <div style={{ fontSize: "20px", margin: "auto" }}>
        <p>Sorry ! Something went wrong</p>
      </div>
      <div style={{ fontSize: "15px", margin: "auto" }}>
        <p>Please, retry later</p>
      </div>
      <div style={{width:'130px', margin:'20px auto'}}>
        <Button
          onClick={() => logOutAndGoBack()}
          type="primary"
          className="form_button"
          size="large"
          block
        >
          Home Page
        </Button>
      </div>
    </div>
  );
}
