import { createSlice } from "@reduxjs/toolkit";
import staticMethods from "antd/es/message";

const userLoggedIn = {
  isLoaded: false,
  userInfo: {},
  sessionToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: userLoggedIn,
  reducers: {
    logIn: (state, { payload }) => {
      state.isLoaded = true;
      state.userInfo = payload.userInfo;
      state.sessionToken = payload.sessionToken;
    },
  },
});

export const { logIn } = authSlice.actions;
export const authReducer = authSlice.reducer;

const userData = {
  id: null,
  firstName: null,
  lastName: null,
  city: null,
  position: { lon: null, lat: null },
  hobbies: [],
  skills: [],
  netCoins: 0,
  businessInterests: [],
  businessExperience: [],
  imgProfileLocation: null,
  bio: null,
  isProfileCompleted: false,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState: userData,
  reducers: {
    fetchUserData: (state, { payload }) => {
      state.id = payload.id ? payload.id : state.id;
      state.firstName = payload.firstName ? payload.firstName : state.firstName;
      state.lastName = payload.lastName ? payload.lastName : state.lastName;
      state.city = payload.city ? payload.city : state.city;
      state.netCoins = payload.netCoins ? payload.netCoins : state.netCoins;
      state.position = payload.position ? payload.position : state.position;
      state.hobbies = payload.hobbies ? payload.hobbies : state.hobbies;
      state.skills = payload.skills ? payload.skills : state.skills;
      state.isProfileCompleted = payload.isProfileCompleted
        ? payload.isProfileCompleted
        : state.isProfileCompleted;
      state.businessInterests = payload.businessInterests
        ? payload.businessInterests
        : state.businessInterests;
      state.businessExperience = payload.businessExperience
        ? payload.businessExperience
        : state.businessExperience;
      state.imgProfileLocation = payload.imgProfileLocation
        ? payload.imgProfileLocation
        : state.imgProfileLocation;
      state.bio = payload.bio ? payload.bio : state.bio;
    },
  },
});

export const { fetchUserData } = userDataSlice.actions;
export const fecthUserDataReducer = userDataSlice.reducer;

const usersMatched = {
  lastUpdateTs: null,
  users: [],
  groups: [],
};

const usersMatchedSlice = createSlice({
  name: "usersMatched",
  initialState: usersMatched,
  reducers: {
    fetchUsersMatched: (state, { payload }) => {
      state.users = payload.users ? payload.users : state.users;
      state.groups = payload.groups ? payload.groups : state.groups;
      state.lastUpdateTs = payload.lastUpdateTs
        ? payload.lastUpdateTs
        : state.lastUpdateTs;
    },
  },
});

export const { fetchUsersMatched } = usersMatchedSlice.actions;
export const fetchUsersMatchedReducer = usersMatchedSlice.reducer;

const usersGroupAndTeams = {
  groups: [],
  teams: [],
};

const usersGroupAndTeamsSlice = createSlice({
  name: "usersGroupAndTeams",
  initialState: usersGroupAndTeams,
  reducers: {
    fetchUsersGroupAndTeams: (state, { payload }) => {
      state.groups = payload.groups ? payload.groups : state.groups;
      state.teams = payload.teams ? payload.teams : state.teams;
    },
    updateUserGroups: (state, { payload }) => {
      const updatedGroups = state.groups.map((group) => {
        if (group.id === payload.groupId) {
          return {
            ...group,
            invitedUsers: [...group.invitedUsers, payload.invitedUserId],
          };
        }
        return group;
      });
      state.groups = updatedGroups;
    },
  },
});

export const { fetchUsersGroupAndTeams, updateUserGroups } =
  usersGroupAndTeamsSlice.actions;
export const fetchUsersGroupAndTeamsReducer = usersGroupAndTeamsSlice.reducer;

const categories = {
  skills: null,
  businessTypes: null,
  hobbies: null,
};

const categoriesSlice = createSlice({
  name: "categoriesSlice",
  initialState: categories,
  reducers: {
    fecthCategories: (state, { payload }) => {
      state.skills = payload.skills;
      state.businessTypes = payload.businessTypes;
      state.hobbies = payload.hobbies;
    },
  },
});

export const { fecthCategories } = categoriesSlice.actions;
export const fecthCategoriesReducer = categoriesSlice.reducer;

const notifications = {
  readNotifications: [],
  newNotifications: [],
};

const upsertNotifications = (notifications, newNotifications) => {
  const updatedNotifications = [...notifications];
  newNotifications.forEach((newNotification) => {
    const index = updatedNotifications.findIndex(
      (notification) =>
        notification.notificationId === newNotification.notificationId
    );
    if (index !== -1) {
      updatedNotifications[index] = {
        ...updatedNotifications[index],
        ...newNotification,
      };
    } else {
      updatedNotifications.push(newNotification);
    }
  });
  return updatedNotifications;
};

const sortByDateDescending = (notifications) => {
  return notifications.sort((a, b) => new Date(b.date) - new Date(a.date));
};

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: notifications,
  reducers: {
    fecthNotification: (state, { payload }) => {
      if (payload.readNotifications) {
        state.readNotifications =
          payload?.method == "append"
            ? sortByDateDescending([
                ...state.readNotifications,
                ...payload.readNotifications,
              ])
            : payload.readNotifications;
      }
      if (payload.newNotifications) {
        state.newNotifications =
          payload?.method == "append"
            ? sortByDateDescending(
                upsertNotifications(
                  state.newNotifications,
                  payload.newNotifications
                )
              )
            : payload.newNotifications;
      }
    },
  },
});

export const { fecthNotification } = notificationsSlice.actions;
export const fecthNotificationReducer = notificationsSlice.reducer;

const chatState = {
  chats: [],
};

const updateChatObjectAndIncrement = (chats, payload) => {
  const chatIndex = chats.findIndex(
    (object) => object.roomId === payload.roomId
  );
  if (chatIndex !== -1) {
    chats[chatIndex].unreadMessages += 1;
    chats[chatIndex].lastMessage = payload.lastMessage;
    chats[chatIndex].updatedAt = payload.updatedAt;
    return { chatIndex: chatIndex, updatedChat: chats[chatIndex] };
  }
  return { chatIndex: -1, updatedChat: null }; // In caso non si trovi l'oggetto
};

const restChatIncrement = (chats, payload) => {
  const chatIndex = chats.findIndex(
    (object) => object.roomId === payload.roomId
  );
  if (chatIndex !== -1) {
    chats[chatIndex].unreadMessages = 0;

    return { chatIndex: chatIndex, updatedChat: chats[chatIndex] };
  }
  return { chatIndex: -1, updatedChat: null }; // In caso non si trovi l'oggetto
};

const chatSlice = createSlice({
  name: "chatSlice",
  initialState: chatState,
  reducers: {
    fetchChatUpdates: (state, { payload }) => {
      const result = updateChatObjectAndIncrement(state.chats, payload);
      if (result.chatIndex !== -1) {
        state.chats[result.chatIndex] = result.updatedChat;
      }
    },
    initializeChatArray: (state, { payload }) => {
      state.chats = payload.chats;
    },
    resetChatNumMessages: (state, { payload }) => {
      const result = restChatIncrement(state.chats, payload);
      if (result.chatIndex !== -1) {
        state.chats[result.chatIndex] = result.updatedChat;
      }
    },
  },
});

export const { fetchChatUpdates, initializeChatArray, resetChatNumMessages } =
  chatSlice.actions;
export const fetchChatReducer = chatSlice.reducer;

const customSearch = {
  getPeopleParams: {},
  getGroupsParams: {},
};

const customSearchSlice = createSlice({
  name: "customSearchSlice",
  initialState: customSearch,
  reducers: {
    fetchPeopleQueryParameters: (state, { payload }) => {
      state.getPeopleParams = payload.getPeopleParams
        ? payload.getPeopleParams
        : state.getPeopleParams;
    },
    fetchGroupsQueryParameters: (state, { payload }) => {
      state.getGroupsParams = payload.getGroupsParams
        ? payload.getGroupsParams
        : state.getGroupsParams;
    },
  },
});

export const { fetchPeopleQueryParameters, fetchGroupsQueryParameters } =
  customSearchSlice.actions;
export const customSearchReducer = customSearchSlice.reducer;


const createdPost = {post:{}}

const createdPostSlice = createSlice({
  name: 'createdPostSlice',
  initialState: createdPost,
  reducers:{
    saveCreatedPost: (state, {payload}) =>{
      state.post = {...payload.post}
    }
  }

})

export const { saveCreatedPost } =
createdPostSlice.actions;
export const saveCreatedPostReducer = createdPostSlice.reducer;